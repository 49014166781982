<template>
  <div>
    <iframe
      :src="referralPageIFrameLinks"
      class="iFrameFormat"
      title="Refer a Friend">
    </iframe>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    props: [
    ],
    data: () => ({
      region: process.env.VUE_APP_REGION,
      referralPageIFrameLinks: ''
    }),
    methods: {
      ...mapActions("admin", ["getSettingsByKey"]),
      async getIFrameReferralLinks() {
        try {
          this.$vs.loading();

          const { data } = await this.getSettingsByKey({
            key: "referralPageIFrameLinks",
            exemptUrlClean: true
          })

          this.referralPageIFrameLinks = data.data.value[this.region];
        } catch (error) {
          console.log(`Error fetching refferal page link: ${error}`)
        } finally {
          this.$vs.loading.close()
        }
      },
    },
    created() {
      this.getIFrameReferralLinks();
    },
  };
</script>

<style scoped>
  .iFrameFormat {
    border: 0;
    width: 100%;
    height: 100vh;
  }
</style>